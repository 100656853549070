<template>
  <div>
    <div class="table">
      <div class="table__title-wrapper">
        <div class="container">
          <div class="table__title-inner">
            <div class="table__title-item">N/S</div>
            <div class="table__title-item">Business Name</div>
            <div class="table__title-item">Address</div>
            <div class="table__title-item">Contact Name</div>
            <div class="table__title-item">Business Phones</div>
          </div>
        </div>
      </div>

      <div class="table__main">
        <Customer
          v-for="customer in customers"
          :key="customer.id"
          :info="customer"
          @click.native="$router.push('/users/customers/' + customer.id)"
        />
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Customer from '@/views/Admin/Customers/Customer'
import InfiniteLoading from 'vue-infinite-loading'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'EnterpriseCustomers',
  components: {Customer, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapState({
      customers: s => s.customers.enterprise.customers,
      pagination: s => s.customers.enterprise.pagination,
      page: s => s.customers.enterprise.page
    }),

    hasMoreResults() {
      return this.customers.length < this.pagination.total && this.page > 1
    }
  },
  async created() {
    if (!this.customers?.length) {
      await this.fetch()
    }
  },
  methods: {
    ...mapActions({
      fetch: 'customers/enterprise/fetch'
    }),
    showAddCustomer() {
      this.$root.$emit('ShowSidebar', {
        sidebarName: 'AddCustomer',
        sidebarStyles: 'width: 700px'
      })
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('customers/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
